<template>
    <section class="bill">
        <!-- <div>
            <el-button type="primary" size="small" @click="openDialog(null,'chong')">￥ 充值</el-button>
        </div> -->
        <div class="table-title" style="margin-top:0;margin-bottom: 5px;">待处理账单</div>
        <r-e-table ref="communityTableRef1" :dataRequest="getUnPaidTableData" :columns="houseBillColumns" :height="300"
                   show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="150">

                <template slot-scope="{ row }">
<!--                    <div class="table-tools">-->
<!--                        <span class="table-btn" @click="look(row)">详情</span>-->
<!--                        <span class="table-btn" v-if="!row.tuiFang" @click="update(row)">修改</span>-->
<!--                        <span class="table-btn" v-if="!row.tuiFang && getButtonMenu('CANCLE_BILL_BUTTON')" @click="openDialog(row,'he')">核销</span>-->
<!--&lt;!&ndash;                        <span class="table-btn" @click="cui(row)">催款</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="table-btn" @click="deduction(row)">抵扣</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      <span class="table-btn" @click="deleteclick(row)">删除</span>&ndash;&gt;-->
<!--                    </div>-->

<!--2024-10-15-修改操作以及同步权限-->
                  <el-dropdown>
                            <span class="el-dropdown-link">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="look(row)">
                        <span class="table-btn">详情</span>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="!row.tuiFang && getButtonMenu('PUTMONEY')" @click.native="update(row)">
                        <span class="table-btn">修改</span>
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="cui(row)">
                        <span class="table-btn">催款</span>
                      </el-dropdown-item>
                      <!-- 2024/05/08 张晓瑜新增抵扣 -->
                      <!-- v-if="row.typeName==='押金'" -->
                      <el-dropdown-item @click.native="deduction(row)">
                        <span class="table-btn">抵扣</span>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="!row.tuiFang && getButtonMenu('CANCLE_BILL_BUTTON')"
                                        @click.native="openDialog(row,'he')">
                        <span class="table-btn">核销</span>
                      </el-dropdown-item>
                      <!--             2023-11-28-天气晴-中午新店开业踩坑-心情-暴风雨-->
                      <el-dropdown-item v-if="getButtonMenu('DELETECLICK')"
                                        @click.native="deleteclick(row)">
                        <span class="table-btn">删除</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title" style="margin-top:0;margin-bottom: 5px;">已处理账单</div>
        <r-e-table ref="communityTableRef2" :dataRequest="getPaidTableData" :columns="houseBillColumns" :height="300"
                   show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn"  v-if="row.stateCode=== 600602 && row.typeName === '押金' && row.checkOut === false" @click="transfer(row)">转移</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>


        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" show-footer top="10vh" width="1700px"
                    @click-submit="detailsDialogVisible=false" @click-cancel="detailsDialogVisible=false" @close="detailsDialogVisible=false">
            <bill-details ref="billDetails" :billData="billData"/>
        </r-e-dialog>

        <layer-write-off-bill ref="layerWriteOffBill" @clickCancel="clickCancel"/>

        <dialog-add-bill ref="dialogAddBill" @clickCancel="clickCancel"/>

        <layer-transfer-deposit-bill ref="layerTransferDepositBill" @clickCancel="clickCancel" />
<!--      账单抵扣弹框-->
      <dialog-add-deduction ref="dialogAddDeduction" @clickCancel="clickCancel" />
<!--      抵扣弹框 -->
<!--      <layer-write-off-bill ref="layerWriteOffBill" @clickCancel="clickCancel"/>-->
    </section>
</template>

<script>
    import { houseBillColumns } from "@/views/rental-management/tenant-management/data";
    import * as houseApi from "@/api/house-resource";
    import {buttonCookies} from "@storage/cookies";
    import {numberFormat, timeFormat} from "@custom";
    import {getContractByUuidOrLeasorUuid, getdeleteclickApi} from "@/api/contract";
    import {MessageSuccess, MessageWarning} from "@custom/message";
    export default {
        name: "bill",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                houseBillColumns: houseBillColumns(this),
                billData: null,
                detailsDialogVisible: false,
            };
        },
        components: {
            billDetails: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
            layerWriteOffBill: () => import("@/views/bill-management/payment-record/components/layer-write-off-bill"),
            dialogAddBill: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-bill"),
            layerTransferDepositBill: () => import('@/views/bill-management/payment-record/components/layer-transfer-deposit-bill'),
            dialogAddDeduction:() => import( '@/views/rental-management/tenant-management/components/part/components/dialog-add-deduction.vue'),
          // layerWriteOffBill
        },
        async created() {
            // 获取用户uuid
            const {uuid} = this.tenantData;
            this.uuid = uuid;
        },
        methods: {
            async getUnPaidTableData(params) {
                // 获取用户未处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserUnPaidApi(params);
            },

            getPaidTableData(params) {
                // 获取用户已处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserPaidApi(params);
            },

            handleSearch() {
                this.$refs["communityTableRef1"].getTableData();
                this.$refs["communityTableRef2"].getTableData();
            },

            openDialog(data, type) {
                this.$refs["layerWriteOffBill"].openDialog(data);
            },

            async clickCancel() {
                await this.$emit('getDeposit');
                await this.$emit('handleSearch');
                this.handleSearch();
            },

            look(data) {
                this.billData = data;
                this.detailsDialogVisible = true;
            },

            getButtonMenu(menu) {
                return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
            },

            update(data) {
                this.$refs["dialogAddBill"].openDialog(data);
            },

            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';

                });
                return sums;
            },

            transfer(data){
                this.$refs["layerTransferDepositBill"].openDialog(data);
            },
          //催收-2024-10-15
          async cui(data) {
            console.log(data)
            const {startDate, endDate} = data;
            // console.log()
            let dataTime = {billStartDate: timeFormat(new Date(startDate)), billEndDate: timeFormat(new Date(endDate))}
            // 账单开始时间：billStartDate    账单结束时间：billEndDate
            const that = this;
            const {leasorUuid} = data;
            const {apartmentUuid} = data;
            const paramData = {uuid: apartmentUuid, leasorUuid}
            console.log(paramData)
            let res = await getContractByUuidOrLeasorUuid(paramData).catch(() => MessageWarning("该房源没有合同,无法催款", 1000));
            if (res) {
              let {msg} = res;
              const loadingOptions = that.loadingOptions;
              const loading = that.$loading({...loadingOptions});
              houseApi.reminder({uuid: msg,...dataTime}).then(res => {
                MessageSuccess('催款成功');
              }).finally(() => loading.close());
            } else MessageWarning("该房源没有合同,无法催款", 1000);
          },
// 2024/10/15 陈守亮新增打开账单抵扣弹框
          deduction(data) {
            const {leasorUuid} = data;
            this.$refs["dialogAddDeduction"].openDialog(data);
          },
          // 删除按钮
          deleteclick(row){
            console.log(row)
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {


              await  getdeleteclickApi({uuid:row.uuid}).then(res => {
                console.log(res)
                if(res.msg === '账单删除成功'){
                  this.clickCancel()
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                }else {
                  this.$message({
                    type: 'info',
                    message: '删除失败请联系管理员'
                  });
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
          },
        }
    }
</script>

<style scoped>

</style>
